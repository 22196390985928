<template>
  <v-container
    fluid
    v-if="relatorioCarregado"
  >
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-row>
        <v-col>
          <informacoes-basicas
            ref="refInformacoesBasicas"
            :nomeRelatorioModelo="relatorio.nome"
            :descricao="relatorio.descricao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <parametros
            ref="refParametros"
            :relatorio="relatorio"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="success"
            style="float: right"
            min-width="100"
            class="ml-3"
            :disabled="invalid"
            :loading="loading"
            @click="salvar()"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';
import RelatoriosModelosService from '@/services/RelatoriosModelosService';
import routes from '@/store/modules/routes';

export default {
  components: {
    InformacoesBasicas: () =>
      import('@/components/relatorios/modelos/InformacoesBasicas'),
    Parametros: () => import('@/components/relatorios/modelos/Parametros')
  },

  created() {
    this.getRelatorioModelo();
  },

  data: () => ({
    relatorio: {},
    loading: false,
    nome: ''
  }),

  computed: {
    relatorioCarregado() {
      return JSON.stringify(this.relatorio) !== '{}';
    }
  },

  methods: {
    getRelatorioModelo() {
      RelatoriosModelosService.getRelatorioModelo(this.$route.params.id)
        .then((response) => (this.relatorio = response.data.data))
        .catch(() =>
          this.$toast.error('Erro ao recuperar relatório modelo.', '', {
            position: 'topRight'
          })
        );
    },

    salvar() {
      let data = {
        modelo_id: this.$route.params.id,
        nome: this.$refs.refInformacoesBasicas.exportCampos(),
        parametros: this.$refs.refParametros.exportCampos()
      };

      this.loading = true;

      RelatoriosService.saveRelatorioFromModelo(data)
        .then(() => {
          this.changeTipoListaConsulta();
          this.$toast.success('Relatório modelo salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['RELATORIOS_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar relatório modelo.', '', {
            position: 'topRight'
          });
          this.loading = false;
        });
    },

    changeTipoListaConsulta() {
      this.$store.dispatch('setTipoListaRelatorio', {
        tipo: 'modelos',
        titulo: `Relatórios Modelos :: ${this.relatorio.nome}`,
        grupo: this.relatorio.grupo_relatorio.id,
        relatorio_modelo_id: this.$route.params.id
      });
    }
  }
};
</script>
